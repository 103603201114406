"use client";
import { signal } from "@preact/signals-react";
import { ReactNode, useEffect } from "react";

export const hashParams = signal<URLSearchParams>(new URLSearchParams(""));

export function updateHashParams(
  params: Record<string, string | null | undefined>
) {
  const newParams = new URLSearchParams(hashParams.peek());
  for (const [key, value] of Object.entries(params)) {
    if (!value) {
      newParams.delete(key);
      continue;
    }
    newParams.set(key, value);
  }
  window.location.hash = "#" + newParams.toString();
}

export function WithHashParams({ children }: { children: ReactNode }) {
  useEffect(() => {
    function updateHashParams() {
      const hash = window.location.hash.substring(1);
      const params = new URLSearchParams(hash);
      if (params.toString() === hashParams.peek().toString()) {
        return;
      }
      hashParams.value = params;
    }
    updateHashParams();
    window.addEventListener("hashchange", updateHashParams);
    return () => {
      window.removeEventListener("hashchange", updateHashParams);
    };
  }, []);
  return <>{children}</>;
}
